// eslint-disable-next-line @typescript-eslint/no-explicit-any
type I18n = any
type FileStore = ReturnType<typeof useFileStore>

export function useDownloadFile(createSnackbar: (snackbar: Snackbar) => Snackbar, $i18n: I18n, fileStore: FileStore) {
  return async (fileId: string, fileName: string, extension: string) => {
    const fileNameWithExtension = `${fileName}.${extension}`
    const notification = createSnackbar({
      title: $i18n.t("downloading", { file: fileNameWithExtension }),
      type: "info",
      timeout: -1,
    })

    try {
      const link = document.createElement("a")
      link.download = fileNameWithExtension
      link.href = await fileStore.getFileUrl(fileId)
      link.click()

      notification.type = "success"
      notification.title = $i18n.t("downloadSuccess", [fileNameWithExtension])
      notification.timeout = 3000
      notification.closable = true
    } catch {
      notification.type = "error"
      notification.title = $i18n.t("downloadFailed", [fileNameWithExtension])
      notification.timeout = 3000
      notification.closable = true
    }
  }
}
